import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Blog extends React.Component {
  renderBlogRoll = posts => {
    const colors = [
      "bg-red-100",
      "bg-blue-100",
      "bg-orange-100",
      "bg-green-100",
    ]
    const results = []
    posts.forEach(({ node }, index) => {
      results.push(
        <div
          className={`py-8 px-12 mb-4 ${
            index < 3 ? colors[index] : colors[index % 4]
          } rounded`}
        >
          <Link to={`blog${node.fields.slug}`}>
            <div className="flex flex-col justify-between">
              <div>
                <small className="text-gray-800 tracking-wide">
                  {node.frontmatter.date}
                </small>
                <h3 className="mt-1 text-2xl font-bold text-gray-800">
                  {node.frontmatter.title}
                </h3>
              </div>
              <div>
                <button className="mt-6 bg-gray-800 rounded text-white py-2 px-8 hover:bg-blue-700 transition duration-150 ease-in-out">
                  Read
                </button>
              </div>
            </div>
          </Link>
        </div>
      )
    })

    return results
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="All posts" />
        <h1 className="mt-16 md:mt-32 text-4xl font-bold">Writing</h1>
        <p className="max-w-2xl">
          This is a collection of all the things that are interesting, interest
          me, or something I am interested in. I generally focus on startups,
          occasional tech, design, and sleight of hand. Sprinkle in some
          philosophy and whiskey and you get a nice stew going.
        </p>

        <div className="mt-8 block sm:grid grid-cols-2 gap-4">
          {this.renderBlogRoll(posts)}
        </div>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
